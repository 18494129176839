.about-container {
    text-align: center;
    padding-left: 25%;
    padding-right: 25%;

    h2 {
        font-family: "Courier New", monospace;
        font-size: 24px;
        margin-bottom: 50px;
        position: relative;

        span[contentEditable="true"] {
            outline: none;
            caret-color: transparent; // Hide the real cursor
        }

        .cursor {
            display: inline-block;
            width: 8px;
            height: 20px;
            background-color: black;
            animation: blink 0.8s infinite;
        }
    }

    .info-container .section {
        display: flex;
        justify-content: space-between;
        gap: 25px;
        align-items: center;
        margin-bottom: 20px;

        // Add specific styling for each section if necessary

        @media (max-width: 1150px) {
            flex-direction: column; // Change to column layout on smaller screens
            text-align: center;

            img {
                margin-top: 20px; // Add some spacing between text and image
                order: 1; // Move the image below the text
            }
        }
    }

    .personal-text,
    .professional-text,
    .college-text {
        max-width: 60%; /* Adjust as needed */

        @media (max-width: 768px) {
            max-width: 100%; // Use full width on smaller screens
        }
    }

    img {
        max-height: 350px;
        border-radius: 3%;
        outline: 2px solid #333;

        @media (max-width: 768px) {
            order: -1; // Move the image above the text on smaller screens
        }
    }

    p {
        text-align: left;
    }

    h1 {
        text-align: left;
    }

    @keyframes blink {
        50% {
            opacity: 0;
        }
    }
}
