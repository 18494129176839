html {
  overflow-x: hidden;
}

.navbar {
  background-color: #333;
  color: #fff;
  padding: 20px 0;
  margin-bottom: 50px;
  width: 100vw;
  position: relative;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1600px;
  margin: 0 auto;
}

.logo-link {
  text-decoration: none;
  color: #fff;
  font-size: 1.5em;
  font-weight: bold;
  flex: 1;
  margin-left: 5%;
}

.nav-links {
  position: relative;
  display: flex;
  justify-content: center;
  flex: 1;
}

.navbar-container::after {
  content: "";
  flex: 1;
  margin-right: 5%;
}

.link {
  text-decoration: none;
  color: #fff;
  margin: 0 15px;
  position: relative;
}

.underline {
  position: absolute;
  bottom: 0;
  height: 2px;
  background-color: red;
  transition:
    left 0.3s ease,
    width 0.3s ease;
}

.menu-toggle {
  display: none;
  background: none;
  border: none;
  color: #fff;
  font-size: 1.5em;
  cursor: pointer;
  position: absolute;
  right: 5%;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10; // Ensure the button is above other elements
}

.dropdown-menu {
  display: none;
  position: absolute;
  background-color: #333;
  right: 5%;
  top: 60px;
  z-index: 1000;

  a {
    display: block;
    padding: 10px 20px;
    color: white;
    text-decoration: none;

    &:hover {
      background-color: #444;
    }
  }
}

// Media Query for Small Screens
@media (max-width: 768px) {
  .menu-toggle {
    display: block;
  }

  .nav-links {
    display: none;
  }

  .dropdown-menu {
    display: block;
  }
}
