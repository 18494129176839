.contact-container {
    text-align: center;

    form {
        max-width: 400px;
        margin: 0 auto;
        padding: 20px;
        border: 1px solid #333;
        border-radius: 8px;

        textarea {
            resize: none;
        }

        label {
            display: block;
            margin-bottom: 10px;
            font-size: 14px;
            text-align: left;

            input,
            textarea {
                width: 100%;
                padding: 8px;
                box-sizing: border-box;
                margin-top: 4px;
                border: 1px solid #ccc;
                border-radius: 4px;
                font-size: 14px;

                &:focus {
                    outline: none;
                    border-color: #333;
                }
            }
        }

        button {
            background-color: #333;
            color: #fff;
            padding: 10px 15px;
            border: none;
            border-radius: 4px;
            cursor: pointer;
            font-size: 16px;
            transition: background-color 0.3s;
            /* Add a smooth transition for color change */

            &:hover {
                background-color: #555;
            }
        }
    }

    h2 {
        font-family: "Courier New", monospace;
        font-size: 24px;
        margin-bottom: 20px;
        position: relative;

        span[contentEditable="true"] {
            outline: none;
            caret-color: transparent; // Hide the real cursor
        }

        .cursor {
            display: inline-block;
            width: 8px;
            height: 20px;
            background-color: black;
            animation: blink 0.8s infinite;
        }
    }

    @keyframes blink {
        50% {
            opacity: 0;
        }
    }
}