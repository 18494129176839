.bracket {
    display: flex;
    flex-direction: row; /* Change to row-reverse */
    align-items: center;
    margin-top: 25px;
    position: relative;
}

.line {
    position: absolute; /* Position the lines absolutely within the bracket container */
    stroke: black; /* Line color */
    stroke-width: 2px; /* Line thickness */
}

.round {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the rounds horizontally */
    margin-left: 60px; /* Adjust margin as needed */
}

.round-matches {
    display: flex;
    flex-direction: column;
}

.match {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 10px;
}

input[type="text"] {
    width: 100px; /* Adjust width as needed */
    margin: 0 10px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 3px;
}

button {
    margin-left: 10px;
    padding: 5px 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 3px;
    cursor: pointer;
}

button:hover {
    background-color: #0056b3;
}
