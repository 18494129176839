.skill-container {
    width: 95px;
    height: 95px;
    border-radius: 10px;
    margin: 4%;
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Center both horizontally and vertically */
    padding: 8px;
    transition: transform 0.2s ease-in-out; /* Add a transition for smooth scaling */
}

.skill-container:hover {
    transform: scale(1.1); /* Scale up by 10% on hover */
}

.skill-image {
    max-width: 65px;
    max-height: 65px;
    border-radius: 10px;
    margin-top: 23px;
    margin-bottom: -10px;
    transition: transform 0.2s ease-in-out;
}

.skill-container:hover .skill-image {
    transform: scale(1.1); /* Scale up by 10% on hover */
}

p {
    text-align: center;
}
