.home-container {
    text-align: center;

    .typing-section {
        max-width: 50%;
    }

    h2 {
        font-family: "Courier New", monospace;
        font-size: 24px;
        margin-bottom: 50px;
        position: relative;

        span[contentEditable="true"] {
            outline: none;
            caret-color: transparent; // Hide the real cursor
        }

        .cursor {
            display: inline-block;
            width: 8px;
            height: 20px;
            background-color: black;
            animation: blink 0.8s infinite;
        }
    }

    .content {
        max-width: 100%; // Allow content to take up full width within the container
        padding: 0 20px; // Add some padding to the content
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .text {
            width: 55%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            @media (max-width: 768px) {
                // Hide the smiley face when the screen size is 768px or smaller
                h1 {
                    display: none;
                }
            }
        }

        p {
            max-width: 600px;
            margin-bottom: 20px;
            font-size: large;
            text-align: left;
        }

        h1 {
            margin-left: 15%;
            font-size: 70px;

            @media (max-width: 768px) {
                // Adjust the margin and font size for smaller screens
                margin-left: 0;
                font-size: 50px;
            }
        }

        .profile-image {
            width: 150px;
            height: 150px;
            border-radius: 50%;
            margin-top: 20px;
        }
    }

    .skills-wrapper {
        width: 100%; /* Use 100% width */
        display: flex;
        flex-wrap: wrap; /* Allow skills to wrap to the next row */
        justify-content: space-around; /* Center skills within the container */

        @media (min-width: 768px) {
            /* Adjust the layout for larger screens (5 skills per row) */
            max-width: 750px; /* Set a maximum width for better centering on larger screens */
        }

        @media (min-width: 1200px) {
            /* Further adjust the layout for even larger screens */
            max-width: 1100px;
        }
    }

    @keyframes blink {
        50% {
            opacity: 0;
        }
    }
}
