.project-details-details-container {
    text-align: center;
}

.project-title {
    margin-top: 20px;
    margin-bottom: 20px;
}

.project-content-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 15px;
    margin-top: 30px;
    margin-bottom: 40px;
}

.project-details-image-container {
    width: 100%;
    max-width: 600px;
    margin-right: 20px;
}

.project-details-image {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    border-radius: 10px;
    margin-bottom: 20px;
    border: 2px solid #333;
}

.project-details-description-container {
    width: 100%;
    max-width: 600px;
    text-align: left;
}

.project-details-description {
    margin: 10px 0;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: unset;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    text-align: left;
    white-space: pre-wrap;
}

.skills-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-bottom: 10px;
}

.skill-rectangle {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
}

.skill-rectangle:hover {
    transform: scale(1.1);
    border: 1px solid #333;
}

@media (max-width: 768px) {
    .project-content-container {
        flex-direction: column;
        align-items: center;
    }

    .project-details-image-container {
        width: 100%;
        max-width: none;
        margin-right: 0;
    }
}