.footer {
    margin-top: 50px;
    padding: 20px;
}

.footer {
    color: #fff;
    padding: 20px;
    text-align: center;

    .social-links {
        margin-bottom: 10px;

        a {
            color: #333;
            margin: 0 10px;
            text-decoration: none;
            font-weight: bold;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .copyright {
        color: #555;
        font-size: 12px;
    }
}
